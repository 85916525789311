//////////////////////
.SnSearch-box{
    width: 1200px;
    margin: 45px auto 0;
    .SnSearch-tent{
      display: '-webkit-flex'; /* Safari */
      display: flex;
      justify-content: flex-end;
     }
     .SnSearch-tle{
        font-size: 30px;
        font-weight: bold;
        color: #333333;
        height: 38px;
        line-height: 34px;
        margin-right: 25px;
        overflow: hidden;
     }
     .SnSearch-shon{
        width: 100px;
        height: 36px;
        line-height: 36px;
        background: #FFF url(../../assets/screen/screenon.png) no-repeat 68px 12px;
        border: 1px solid #C30D23;
        border-radius: 3px;
        cursor: pointer;
        text-align: left;
        padding: 0 0 0 18px;
        font-size: 14px;
        color: #C30D23;
     }
     .SnSearch-sh{
      width: 100px;
      height: 36px;
      line-height: 36px;
      background: #FFF url(../../assets/screen/screen.png) no-repeat 68px 12px;
      border: 1px solid #222;
      border-radius: 3px;
      cursor: pointer;
      text-align: left;
      padding: 0 0 0 18px;
      font-size: 14px;
      color: #222;
   }
     
     .SnSearch-right{
        width: 100px;
        height: 36px;
        background: #FFFFFF;
        border: 1px solid #BFBFBF;
        border-radius: 3px;
        padding: 9px 0 0;
        margin-left: auto;
     }
     .list-pct{
        display: block;
        width: 50px;
        height: 18px;
        border: none;
        border-right: 1px solid #E5E5E5;
        background: url(../../assets/screen/xspct.png) no-repeat center center;
        float: left;
        cursor: pointer;
     }
     .list-pcton{
      display: block;
      width: 50px;
      height: 18px;
      border: none;
      border-right: 1px solid #E5E5E5;
      background: url(../../assets/screen/xspcton.png) no-repeat center center;
      float: left;
      cursor: pointer;
   }
     .list-tst{
        display: block;
        width: 48px;
        height: 18px;
        border: none;
        background: url(../../assets/screen/xstst.png) no-repeat center center;
        cursor: pointer;
     }
     .list-tston{
      display: block;
      width: 48px;
      height: 18px;
      border: none;
      background: url(../../assets/screen/xstston.png) no-repeat center center;
      cursor: pointer;
   }
     .SnSearch-tn{
      //   height: 140px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 0px rgba(102, 102, 102, 0.3);
        border-radius: 4px;
        margin-top: 24px;
        padding: 22px 28px 24px;
      //   margin-bottom: 36px;

      .ant-select-selector{
         min-height: 40px;
      }
     }
     .SnSelect-style{
        min-width: 150px;
        height: 40px;
        margin-right: 11px;
        margin-bottom: 10px !important;
     }
     .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: 100%;
        height: 40px; 
        line-height: 40px;
        padding: 0 11px;
        border: 1px solid #A0A0A0;
        border-radius: 4px;
     }
     .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 40px;
        font-size: 14px;
        color: #222;
     }
     .ant-select-arrow {  
        color: rgba(49, 49, 49, 1);
        font-size: 12px;
        line-height: 1;
        text-align: center;
        pointer-events: none;
     }
     .SnSpace-style{
        min-width: 170px;
        height: 40px;
        float: right;
     }
     .ant-input {
        color: rgba(49, 49, 49, 1);
        border: 1px solid #A0A0A0;
        border-radius: 4px;
        height: 40px;
     }
     .ant-btn {
        width: 64px;
        height: 40px;
        border-radius: 4px;
        color: rgba(255, 255, 255, 1);
        border-color: #222222;
        background: #222222;
        font-size: 18px;
     }
     .ant-btn-primary:hover, .ant-btn-primary:focus {
        color: #fff;
        border-color: #222;
        background: #222;
     }
     .search-option{
        float: left;
        width: 900px;
        margin-top: 22px;
     }
     .sn-option-on{
        display: inline-block;
        height: 32px;
        background: #FFE6E9;
        border-radius: 4px;
        padding: 0 23px 0 9px;
        font-size: 12px;
        color: #C30D23;
        line-height: 32px;
        margin-right: 20px;
        position: relative;
     }
     .option-poor{
        display: block;
        width: 12px;
        height: 12px;
        line-height: 11px;
        background-color: #C30D23;
        border-radius: 12px;
        color: #fff;
        cursor: pointer;
        text-align: center;
        position: absolute;
        top: 2px;
        right: 2px;
        overflow: hidden;
     }
     .screen-pon{
        float: right;
        width: 80px;
        height: 32px;
        background: #EEEEEE;
        border-radius: 4px;
        font-size: 12px;
        color: #333333;
        line-height: 32px;
        text-align: center;
        border: none;
        cursor: pointer;
        margin: 22px 0 0 20px;
     }
     .screen-pon-on{
        color: #FFFFFF;
        background: #C30D23
     }
     
}
