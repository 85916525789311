.header-wraper {
    width: 100%;
    height: 90px;
    background-color: #000;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1000;

    .header {
        width: 1200px;
        height: 90px;
        margin: 0 auto;
        display: flex;
    }

    .my-logo {
        width: 160px;
        height: 38px;
        margin: 26px 0 0;
    }

    .search-wraper {
        flex: 1;
    }

    .myuser {
        margin: 27px 0 0 0;
        display: inline-flex;
        flex-grow: 1;
        justify-content: flex-end;
        color: aliceblue;
    }

    .search-wraper {
        width: 500px;
        margin: 25px 0 0 187px;
        display: flex;
    }

    .search {
        width: 416px;
        height: 40px;
        padding: 0 0 0 40px;
        background: #fff url(../../assets/search.png) no-repeat 20px 12px;
        border: none;
        border-radius: 40px 0 0 40px;
        line-height: 40px;
    }

    input:focus {
        outline: none;
    }

    .btn {
        width: 85px;
        height: 40px;
        background-color: #C30D23;
        border: none;
        color: #fff;
        font-size: 14px;
        text-align: center;
        border-radius: 0 40px 40px 0;
        line-height: 40px;
        cursor: pointer;
    }

   
}

 //2022-04-02 新增弹窗 ↓↓
 .tidings-Tent {
    width: 500px;
    // padding: 0 10px;
    font-size: 14px;
}
.ant-popover-inner-content{
    padding: 0 !important;
}

.tidings-tle {
    color: #999999;
    border-bottom: 1px solid #D5D5D5;
    line-height: 30px;
    padding: 12px;
}
.tidings-box{
    padding: 12px;
    height: auto;
    max-height: 500px;
    overflow-y: scroll;
}

.tidings-box-li {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #D5D5D5;
    padding: 10px 0;
}

.tidings-box-left p {
    margin-bottom: 0;
}

.tidings-box-nr {
    color: #333333;
    padding-bottom: 8px;
}

.tidings-box-pr {
    font-size: 12px;
    color: #999999;
}

.tidings-box-right {
    font-size: 12px;
    color: #C30D23;
    cursor: pointer;
    width: 50px;
    text-align: right;
}

.tidings-btn-on {
    padding-right: 8px;
}

.tidings-btn {
    cursor: pointer;
}

//2022-04-02 新增弹窗 ↑↑

/* 个人用户中心 */

.avatar-item {
    margin: 5px 18px 0 0;
}

[class*='-col-rtl'] .avatar-item {
    margin-right: 0;
    margin-left: 24px;
}

.myuser .ant-avatar {
    background: url(../../assets/usernews.png) no-repeat center center;
}

.myuser .ant-badge-count {
    width: 16px;
    height: 16px;
    padding: 0;
    line-height: 16px;
    transform: scale(0.8);
    color: #fff;
    font-family: 'ArialMT';
    border-radius: 16px;
    box-shadow: 0 0 1px #C30D23;
    background-color: #C30D23;
    min-width: 16px;
}

.myuseridpct {
    width: 36px;
    height: 36px;
    cursor: pointer;
}

.ant-popover-arrow,
.ant-popover-arrow-content {
    display: none !important;
}

.myusertent {
    padding: 18px 0 15px;
    text-align: center;
}

.ant-popover-inner-content {
    padding: 0;
}

.myusertent01 {
    height: 12px;
    font-size: 12px;
    color: #7F7F7F;
    line-height: 12px;
    padding: 0 30px;
}

.myusertent02 {
    height: 20px;
    font-size: 16px;
    color: #333333;
    line-height: 20px;
    padding: 0 30px 0;
    margin: 12px 0 27px;
}

.myusertent03 {
    height: 44px;
    font-size: 14px;
    color: #333333;
    line-height: 44px;
    cursor: pointer;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    padding: 0 30px;
}

.myusertent04 {
    height: 14px;
    font-size: 14px;
    color: #C30D23;
    line-height: 14px;
    cursor: pointer;
    padding: 0 30px;
    margin-top: 15px;
}

.myuseridnm {
    margin: 0 8px 0 31px;
}

.ibn {
    display: inline-block;
    width: 0;
    height: 0;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-top: 7px solid #fff;
    margin: 0 0 0 5px;
}

/* 添加语言 */
.language-lect {
    display: inline-block;
    position: relative;
    top: 3px;
    padding: 0 0 0 30px;
    // width: 100px;
}


.language-lect .ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 20px;
    background: none;
    color: #fff;
    border: none;
}

.language-lect .ant-select-selector {
    box-shadow: none !important;
}

.language-lect .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 25px;
    padding: 1px 0 0 0;
    background: #000 url(../../assets/updown.png) no-repeat right center;
    background-size: 10px auto;
    color: #fff;
    border: none;
}

.language-lect .ant-select-arrow {
    display: none;
}

.lg-zh {
    display: block;
    height: 20px;
    background: url(../../assets/china.png) no-repeat left center;
    background-size: 20px 20px;
    padding: 0 0 0 30px;
}

.lg-en {
    display: block;
    height: 20px;
    background: url(../../assets/english.png) no-repeat left center;
    background-size: 20px 20px;
    padding: 0 0 0 30px;
}

// 2022-04-02 新增弹窗 ↓↓
.prompt-wraper{
    width: 100%;
    height: 100%;
    background-color:  rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
 }
 .prompt-box{
    position : fixed;
   left : 50%;
   top : 50%;
   width :400px;
   min-height : 280px;
   transform: translate(-50%, -50%);
    padding: 25px 30px 30px;
    background-color: #fff;
    border-radius: 4px;
    z-index: 1005;
 }
 
 .prompt-tle{
    font-size: 20px;
    color: #333333;
    line-height: 26px;
    text-align: center;
    padding: 0px 0 10px;
 }
 .prompt-p-ste{
    font-size: 14px;
    color: #333333;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    margin-bottom: 0;
    border-bottom: 1px solid #E5E5E5;
 }
 .prompt-p-col{
    color: #C30D23;
 }
 .prompt-btn{
    width: 140px;
    height: 40px;
    display: block;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 40px;
    background: #C30D23;
    border: none;
    border-radius: 20px;
    margin: 30px auto 0;
    cursor: pointer;
 }
 // 2022-04-02 新增弹窗 ↑↑

 .popmsgtitle{
    display: flex;
    justify-content: space-between;
 }
 .msg-read-all{
    color: #C30D23;
    cursor: pointer;
 }