.coursed-banner{
    width: 100%;
    height: 240px;
    background-color: #ddd;
}
.coursed-tent{
    width: 1200px;
    padding-top: 80px;
    margin: 0 auto;
}
.coursed-tle{
    display: block;
    height: 40px;
    line-height: 40px;
    font-size: 40px;
    font-family: Arial;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 18px;
}
.coursed-rln{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 26px;
}
.coursed-index{
    cursor: pointer;
}

/* 栏目分类 */
.course-column{
    width: 1200px;
    height: 120px;
    margin: 0 auto;
    padding: 0 48px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(102, 102, 102, 0.3);
    border-radius: 4px;
    position: relative;
    margin-top: -60px;
}
.course-column-ul{
    display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.column-ulli{
    width: 120px;
    height: 120px;
    line-height: 26px;
    border-bottom: 5px solid #fff;
    text-align: center;
    cursor: pointer;
}
.column-ulli span{
    display: block;
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    padding: 29px 0 20px;
}
.column-ulli p{
    font-size: 16px;
    color: #333333;
    padding: 0;
}
.columnactive{
    border-bottom: 5px solid #C30D23;
}
/* 全部课程 */
.course-all{
    width: 1200px;
    margin: 22px auto 40px;
    padding: 35px 40px 45px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(102, 102, 102, 0.3);
    border-radius: 4px;
}
.course-search{
    display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.cs-search-right{
    width: 450px;
    z-index: 0;
}
.course-all-tent{
    margin: 24px 0 0;
    li{
        margin-bottom: 10px;
    }
}
.cs-search-left{
    width: 200px;
    height: 40px;
    font-size: 24px;
    color: #333333;
    line-height: 40px;
}
.course-series{
    display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
    height: 50px;
    background: #EBF4F8;
    border-radius: 4px;
    padding: 0 27px 0 15px;
}
.courseon{
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url(../../assets/course/courseon.png) no-repeat;
    position: relative;
    left: 10px;
    top: 2px;
}
.course-series-left{
    font-size: 14px;
    color: #333333;
    line-height: 50px;
}
.series-nr-list{
    display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #eee;
    padding: 0 27px;
   
}
.srsnr-rt{
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    padding: 0;
}
.srsnr-lf{
    font-size: 14px;
    font-weight: 400;
    color: #666666;
}
.cs-ser-on{
    font-size: 14px;
    color: #333333;
    border: none;
    padding-right: 17px;
    cursor: pointer;
}
.cs-ser-showon{
    background: url(../../assets/course/showon.png) no-repeat right 23px;
}
.course-paging{
    padding: 10px 0 0;
    text-align: right;
}
/* 学习中 */
.study-in .ant-table-thead > tr > th{
    height: 50px;
    background: #EBF4F8;
}
.study-in .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
}
/* 已完成 */
.course-enter{
    display: block;
    padding: 0;
    width: 120px;
    text-align: center;
    height: 32px;
    border: 1px solid #C30D23;
    border-radius: 16px;
    text-indent: 35px;
    font-size: 14px;
    color: #C30D23;
    line-height: 30px;
    cursor: pointer;
    background: url(../../assets/course/enter.png) no-repeat 15px center;
}
/* 考试中 */
.exam-failed{
    width: 120px;
    height: 30px;
    line-height: 30px;
    background: #C30D23;
    border-radius: 15px;
    color: #fff;
    text-align: center;
}
.exam-passed{
    width: 120px;
    height: 30px;
    line-height: 30px;
    background: #32B16C;
    border-radius: 15px;
    color: #fff;
    text-align: center;
}
.exam-makeup{
    width: 120px;
    height: 30px;
    line-height: 30px;
    background: #F1C53E;
    border-radius: 15px;
    color: #fff;
    text-align: center;
}
/* 证书下载按键 */
.coursedload{
    cursor: pointer;
}


//  ********************课程详情 // 
.course-box{
    width: 1200px;
    margin: 50px auto;
    width: 1200px;
    min-height:200px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 0px rgba(102, 102, 102, 0.3);
    border-radius: 4px;
}
.course-title{
    display: flex;
    justify-content: space-between;
    height: 60px;
    line-height: 60px;
    background: #EBF4F8;
    border-radius: 4px 4px 0px 0px;
    font-size: 14px;
    color: #333333;
    padding: 0 27px;
}
.course-tent{
    display: flex;
    justify-content: space-between;
    padding: 80px 60px 45px;
}

.list-box{
    position: relative;
    width: 280px;
    img{
        width: 100%;
    }
}
.start-study{
    margin-top: 34px;
    width: 280px;
    height: 42px;
    background: #C30D23;
    border-radius: 21px;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 42px;
    text-align: center;
    cursor: pointer;
}
.start-ibn{  
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 10px solid #fff;
    border-bottom: 5px solid transparent;
    margin: 0 0 0 10px;
}
.course-tent-right{
    width: 720px;
}
.course-tent-tle h1{
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    height: 30px;
    line-height: 30px;
    padding: 0;
}
.course-tent-tle p{
    font-size: 14px;
    color: #666666;
    line-height: 26px;
    padding: 10px 0 30px;
}
.csetent-box-tle{
    font-size: 18px;
    color: #333333;
    height: 26px;
    line-height: 26px;
    margin-bottom: 20px;
}
.csetent-box-show{
    min-height: 400px;
    background: #F7F7F7;
    box-shadow: 0px 0px 5px 0px rgba(102, 102, 102, 0.25);
    text-align: center;
    position: relative;
    .pdf-box-mask{
        position: absolute;
        left: 0;right: 0;top: 0;bottom: 0;
        background-color: #32B16C20;
    }
}
.csetent-box-show img {
    width: auto;
    height: 400px;
}
.csetent-box-back{
    margin-top: 40px;
}
.csetent-back{
    display: inline-block;
    height: 26px;
    font-size: 16px;
    color: #C30D23;
    line-height: 26px;
    background: url(../../assets/course/back.png) no-repeat left center;
    padding-left: 30px;
    cursor: pointer;
}


.pdf-box{
    position: relative;
    height: calc(100vh - 10px);
    .timer-box{
        position: fixed;
        width: 100px;
        height: 30px;
        line-height: 30px;
        color: #fff;
        text-align: center;
        background-color: red;
        top:80px;
        right: 50px;
        z-index: 1000;
    }
}

.list-pdf {
    display: block;
    width: 34px;
    height: 20px;
    background: #016CB0;
    opacity: 0.5;
    border-radius: 3px;
    font-size: 12px;
    font-family: 'Microsoft YaHei', Arial;
    color: #FFFFFF;
    line-height: 19px;
    position: absolute;
    bottom: 21px;
    right: 21px;
 }

 .auth-course-box{
     display: flex;
     padding: 25px 0 0 15px;
     .chk-box{
         width: calc(100% - 95px - 95px);
     }
     .auth-btn{
         width: 95px;
         text-align: right;
         cursor: pointer;
     }
 }