
.contents{
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 139px;
}
.contents-tle{
    height: 25px;
    color: #333;
    font-size: 24px;
    font-weight: bold;
    line-height: 25px;
    margin-top: 70px;
}
.contents-nr{
    overflow: hidden;
}
.contents-nr ul {
    width: 1250px;
}
.contents-nr ul li{
    float: left;
    width: 280px;
    height: 200px;
    margin: 30px 27px 0 0;
    img{
        width: 100%;
        height: 100%;
    }
}
.pcte-tle{
    display: block;
    height: 26px;
    font-size: 18px;
    color:  #4b4948;;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    position: relative;
    top: -50px;
    left: 0;
}

.lunbo-box{
    width: 100%;
    height: 400px;
    background-position: center center;
    background-repeat: no-repeat;
}
.lunbo-box h1{
    width: 1200px;
    margin: 0 auto;
    padding-top: 190px;
    font-size: 52px;
    font-family: Arial;
    font-weight: bold;
    color: #fff;
    line-height: 64px;
}

.snlist-box {
    width: 1200px;
    margin: 20px auto 0 auto;
 
    .table-info-title{
       display: flex;
       height: 50px;
       align-items: center;
       border-bottom: #e2dfdf solid 1px;
       margin-bottom: 20px;
       // justify-content: space-between;
       .info-title-count{
          width: 120px;
          // span{
          //    font-size: 20px;
          // }
       }
       .info-title-sort{
          padding-left: 20px;
          width: calc(100% - 120px - 150px);
       }
       .info-title-down{
          width: 150px;
          text-align: right;
       }
    }
    .snlist-ul {
       width: 1230px;
    }
 
    .list-sntems {
       width: 280px;
       height: 260px;
       background: #FFFFFF;
       box-shadow: 0px 0px 5px 0px rgba(102, 102, 102, 0.25);
       border-radius: 6px;
       float: left;
       margin: 0 26px 28px 0;
       position: relative;
    }
 
    .list-box {
       width: 280px;
       height: 200px;
       background: #F7F7F7;
       border-radius: 6px 6px 0px 0px;
       text-align: center;
       position: relative;
    }
 
    .list-img {
       width: auto;
       max-width: 200px;
       max-height: 200px;
    }
 
    .list-pdf {
       display: block;
       width: 34px;
       height: 20px;
       background: #016CB0;
       opacity: 0.5;
       border-radius: 3px;
       font-size: 12px;
       font-family: 'Microsoft YaHei', Arial;
       color: #FFFFFF;
       line-height: 19px;
       position: absolute;
       bottom: 21px;
       right: 21px;
    }
 
    .snlist-title {
       height: 60px;
       line-height: 60px;
       overflow: hidden;
       font-size: 16px;
       color: #333333;
       padding-left: 17px;
       text-overflow: ellipsis;
       white-space: nowrap;
    }
 
    .snlist-title-pc {
       display: inline-block;
       width: 16px;
       height: 16px;
       margin-right: 12px;
       position: relative;
       top: 2px;
    }
 
    .pctest {
       background: url(../../assets/screen/pctest.png) no-repeat center center;
    }
 
    .pcvedio {
       background: url(../../assets/screen/pcvedio.png) no-repeat center center;
    }
 
    .list-page {
       text-align: right;
       padding: 45px 0 150px;
    }
 
    /* 分页 */
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
 
       border-radius: 50%;
       outline: none;
       transition: all 0.3s;
    }
 
    .ant-pagination-item {
       border-radius: 50%;
    }
 
    // 列表视图
    .snltsgle-box {
       width: 1200px;
       margin: 0 auto;
    }
 
    .snltsgle-list {
       padding-top: 24px;
    }
 
    .snltsgle-table {
       width: 100%;
    }
 
 
    .list-page {
       text-align: right;
       padding: 45px 0 150px;
    }
 }
 
 /* 分页 */
 .ant-pagination-prev .ant-pagination-item-link,
 .ant-pagination-next .ant-pagination-item-link {
 
    border-radius: 50%;
    outline: none;
    transition: all 0.3s;
 }
 
 .ant-pagination-item {
    border-radius: 50%;
 }
 
 /* table */
 .snltsgle-table {
    text-align: left;
    .snltsgle-thead {
       height: 50px;
       line-height: 5px;
       background-color: #F4F4F4;
       font-size: 14px;
       font-weight: 400;
       color: #666666;
    }
    
    .snltsgle-thead th {
       font-weight: 400;
    }
    
    .th-with01 {
       padding-left: 29px;
       width: auto;
    }
    .th-with02 {
       width: 150px;
    }
    
    .th-with03 {
       width: 130px;
    }
    
    .th-with04 {
       width: 130px;
    }
    .th-with05 {
       width: 160px;
    }
    .th-with06 {
       width: 120px;
    }
    
    .tb-td-border tr td {
       height: 20px;
       line-height: 20px;
       font-size: 14px;
       font-weight: 400;
       padding-top: 15px;
       padding-bottom: 15px;
       border-bottom: 1px solid #eee;
    }
    
    .tb-td-color01 {
       color: #333;
    }
    
    .tb-td-color02 {
       color: #999;
    }
    
    .td-pc {
       padding-left: 28px;
    }
    
    .td-pctest {
       background: url(../../assets/screen/pctest.png) no-repeat left center;
    }
    
    .td-pcvedio {
       background: url(../../assets/screen/pcvedio.png) no-repeat left center;
    }
    
    .td-pcstl {
       background: url(../../assets/screen/stl.png) no-repeat left center;
    }
    
    .td-pcimg {
       background: url(../../assets/screen/image.png) no-repeat left center;
    }
    
    .td-collection {
       display: inline-block;
       padding-left: 22px;
       cursor: pointer;
    }
    
    .td-collectionon {
       background: url(../../assets/screen/collectionon.png) no-repeat left center;
    }
    
    .td-collectionof {
       background: url(../../assets/screen/collectionof.png) no-repeat left center;
    }
 }

 .SnSearch-right{
   width: 100px;
   height: 36px;
   background: #FFFFFF;
   border: 1px solid #BFBFBF;
   border-radius: 3px;
   padding: 9px 0 0;
   margin-left: 20px;
   display: flex;
}
.list-pct{
   display: block;
   width: 50px;
   height: 18px;
   border: none;
   border-right: 1px solid #E5E5E5;
   background: url(../../assets/screen/xspct.png) no-repeat center center;
   float: left;
   cursor: pointer;
}
.list-pcton{
 display: block;
 width: 50px;
 height: 18px;
 border: none;
 border-right: 1px solid #E5E5E5;
 background: url(../../assets/screen/xspcton.png) no-repeat center center;
 float: left;
 cursor: pointer;
}
.list-tst{
   display: block;
   width: 48px;
   height: 18px;
   border: none;
   background: url(../../assets/screen/xstst.png) no-repeat center center;
   cursor: pointer;
}
.list-tston{
 display: block;
 width: 48px;
 height: 18px;
 border: none;
 background: url(../../assets/screen/xstston.png) no-repeat center center;
 cursor: pointer;
}