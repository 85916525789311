.footer-wraper{
    width: 100%;
    height: 54px;
    background: #222222;        
}
.footer{
    width: 1200px;
    height: 54px;
    margin: 0 auto;
    font-size: 12px;
    font-weight: 400;
    color: #7F7F7F;
    line-height: 54px;
    text-align: center;
}
