* {
    margin: 0;
    padding: 0;
}
@font-face {
    font-family: 'Montserrat-VariableFont_wght';
    src: url("./assets/font/Montserrat-VariableFont_wght.ttf");
}
ul,
li {
    list-style: none;
}

//::-webkit-scrollbar-track-piece {
//    background-color: #f8f8f8;
//}
//
//::-webkit-scrollbar {
//    width: 5px;
//    height: 5px;
//}
//
//::-webkit-scrollbar-thumb {
//    background-color: #dcdcdc;
//    background-clip: padding-box;
//    min-height: 28px;
//}
//
//::-webkit-scrollbar-thumb:hover {
//    background-color: #bbb;
//}

::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #999;
}

.loading-box {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    text-align: center;
    z-index: 1000000;
    background-color: #e5e5e550;
}

html,
body,
#root {
    width: 100%;
    height: 100vh;
    position: relative;
    // font-family: Monaco, Inconsolata, Verdana, Arial, Helvetica, sans-serif;
    font-family: "微软雅黑";
    //font-family: "微软雅黑";

}
.loadingz{
    height: 100%;
}
.a-white{
    color: #FFFFFF;
}
.a-white:hover{
    color: #FFFFFF;
}

.list-page {
    text-align: right;
    padding: 28px 0 40px;
    clear: both;

    /* 分页 */
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {

        border-radius: 50%;
        outline: none;
        transition: all 0.3s;
    }

    .ant-pagination-item {
        border-radius: 50%;
    }
}