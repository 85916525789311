.login-bg {
    width: 100%;
    height: 100%;
    background: #fff url(../../assets/user/groundsm.jpg) no-repeat top center;
    position: absolute;
    top: 0;
    left: 0;

    .header-wraper {
        width: 100%;
        height: 90px;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 0;
    }

    .header {
        width: 1200px;
        height: 90px;
        margin: 0 auto;
        display: flex;
    }

    .my-logo {
        width: 160px;
        height: 38px;
        margin: 16px 0 0;
    }

    .search-wraper {
        flex: 1;
    }

    .myuser {
        margin: 26px 0 0 0;
        display: inline-flex;
        flex-grow: 1;
        justify-content: flex-end;
        color: aliceblue;
    }

    .myusertent {
        padding: 18px 0 15px;
        text-align: center;
    }

    .ant-popover-inner-content {
        padding: 0;
    }

    .myusertent01 {
        height: 12px;
        font-size: 12px;
        color: #7F7F7F;
        line-height: 12px;
        padding: 0 30px;
    }

    .myusertent02 {
        height: 20px;
        font-size: 16px;
        color: #333333;
        line-height: 20px;
        padding: 0 30px 0;
        margin: 12px 0 27px;
    }

    .myusertent03 {
        height: 44px;
        font-size: 14px;
        color: #333333;
        line-height: 44px;
        cursor: pointer;
        border-top: 1px solid #E5E5E5;
        border-bottom: 1px solid #E5E5E5;
        padding: 0 30px;
    }

    .myusertent04 {
        height: 14px;
        font-size: 14px;
        color: #C30D23;
        line-height: 14px;
        cursor: pointer;
        padding: 0 30px;
        margin-top: 15px;
    }

    .myuseridnm {
        margin: 0 8px 0 31px;
    }

    .ibn {
        display: inline-block;
        width: 0;
        height: 0;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-top: 7px solid #fff;
        margin: 0 0 0 5px;
    }

    /* 窗口开始 */
    .login-tent {
        position: fixed;
        left: 50%;
        top: 50%;
        width: 420px;
        max-height: 480px;
        transform: translate(-50%, -50%);
        padding: 30px 60px 30px;
        background-color: #fff;
    }

    .logintent-tle {
        color: #333333;
        font-size: 20px;
        font-weight: bold;
        // height: 30px;
        line-height: 30px;
        text-align: center;
        margin-bottom: 30px;
    }

    .login-btn {
        display: inline-block;
        width: 100%;
        height: 48px;
        line-height: 48px;
        font-size: 14px;
        background-color: #fff;
        border: 1px solid #BBBFC4;
        border-radius: 4px;
        padding: 0 10px 0 47px;
        margin-bottom: 15px;
    }

    input:focus {
        outline: none;
    }

    .btn-imlbg {
        background-image: url(../../assets/user/btn-imlbg.png);
        background-repeat: no-repeat;
        background-position: 14px 14px;
    }

    .btn-psdbg {
        background-image: url(../../assets/user/btn-psdbg.png);
        background-repeat: no-repeat;
        background-position: 14px 14px;
    }

    .btn-vfn {
        width: 170px;
        padding: 0 10px 0 14px;
    }

    .btn-vfnimg {
        float: right;
    }

    .btn-on {
        width: 100%;
        height: 48px;
        line-height: 48px;
        font-size: 14px;
        background-color: #C30D23;
        border: none;
        border-radius: 4px;
        font-family: 'Source Han Sans CN';
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
    }

    .fgt-psd {
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        font-family: 'Source Han Sans CN';
        font-weight: 400;
        color: #C30D23;
        text-align: right;
        margin-top: 13px;
    }

    .fgt-psd span {
        cursor: pointer;
    }

    /* 忘记密码 */
    .btn-iml-vfn {
        width: 170px;
        padding: 0 10px 0 14px;
    }

    .btn-iml-vfn-enter {
        width: 100px;
        height: 48px;
        line-height: 48px;
        font-size: 14px;
        background-color: #1B1B1B;
        border: none;
        border-radius: 4px;
        font-family: 'Source Han Sans CN';
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
        float: right;
    }

    .fgt-color-blk {
        color: #333;
    }

    .greybg{
        background-color: #7F7F7F;
    }
    .blackbg{
        background: #1B1B1B
    }
}